<template>
    <div class="engineering-quantity">
        <div class="engineering-quantity-box">
            <div class="engineering-quantity-head">
                <p>选择时间段：</p>
                <el-date-picker
                    v-model="selectTimeData"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                >
                </el-date-picker>
                <button @click="changeTimeFuncion">
                    查询
                </button>
                <!-- 导出excel -->
                <exportDom :dom-id="'#dayReport'" :excel-name="'工程用量统计'"></exportDom>
            </div>
            <div class="engineering-quantity-table">
                <table cellspacing="0" border="1" id="dayReport">
                    <tr>
                        <td>工程类型</td>
                        <td class="tableTitle" v-for="(item, index) in gcyltjData.list" :key="index">
                            {{ item.name }}
                        </td>
                        <td>合计</td>
                    </tr>
                    <tr>
                        <td>工程数量(个)</td>
                        <td v-for="(item, index) in gcyltjData.list" :key="index">
                            {{ item.gcsl }}
                        </td>
                        <td>{{ gcslTotal }}</td>
                    </tr>
                    <tr>
                        <td>预计方量(方)</td>
                        <td v-for="(item, index) in gcyltjData.list" :key="index">
                            {{ item.yjfl }}
                        </td>
                        <td>{{ yjflTotal }}</td>
                    </tr>
                    <tr>
                        <td>已用方量(方)</td>
                        <td v-for="(item, index) in gcyltjData.list" :key="index">
                            {{ item.yyfl }}
                        </td>
                        <td>{{ yyflTotal }}</td>
                    </tr>
                    <tr>
                        <td>订单数量(个)</td>
                        <td v-for="(item, index) in gcyltjData.list" :key="index">
                            {{ item.ddsl }}
                        </td>
                        <td>{{ ddslTotal }}</td>
                    </tr>
                    <tr>
                        <td>发货单数量(个)</td>
                        <td v-for="(item, index) in gcyltjData.list" :key="index">
                            {{ item.fhdsl }}
                        </td>
                        <td>{{ fhdslTotal }}</td>
                    </tr>
                </table>
            </div>
            <div class="engineering-quantity-body">
                <div class="quantity-of-works body-box-two">
                    <h1>工程数量</h1>
                    <div class="see-number-wrapper">
                        <div class="see-number-block">
                            <div>
                                <p v-for="(item, index) in numberListA" :key="index">
                                    <span>{{ item.gcsl }}</span>
                                    {{ item.name }}
                                </p>
                            </div>
                            <div>
                                <p v-for="(item, index) in numberListB" :key="index">
                                    <span>{{ item.gcsl }}</span>
                                    {{ item.name }}
                                </p>
                            </div>
                            <div>
                                <p v-for="(item, index) in numberListC" :key="index">
                                    <span>{{ item.gcsl }}</span>
                                    {{ item.name }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="volume-comparison body-box-two">
                    <div class="echarts-two-right">
                        <h1>方量对比</h1>
                        <!-- <p @click="showLookComp = true"> -->
                        <p @click="showAlertTable = true">
                            数据钻取分析
                        </p>
                    </div>
                    <div style="width:100%;height:calc(100% - .4rem);margin-top:.2rem" id="volumeComparisonId"></div>
                </div>
            </div>
            <lookCompleteData v-if="showLookComp" :select-time-data="selectTimeData" @closeChildAlert="closeChildAlert"></lookCompleteData>
            <alertTable v-if="showAlertTable" :select-time-data="selectTimeData" @closeChildAlert="closeChildAlert"></alertTable>
        </div>
    </div>
</template>

<script>
import exportDom from './components/export';
// import addBgImages from './utils';
import lookCompleteData from './components/engineeringQuantity/lookCompleteData';
import alertTable from './components/engineeringQuantity/index';
export default {
    data() {
        return {
            showLookComp: false,
            showAlertTable: false,
            gcyltjData: [],
            selectTimeData: [],
            numberList: [],
            numberListA: [],
            numberListB: [],
            numberListC: [],
            yyflList: [],
            yjflList: [],
            nameList: [],
            gcslTotal: 0,
            yjflTotal: 0,
            yyflTotal: 0,
            ddslTotal: 0,
            fhdslTotal: 0,
        };
    },
    components: {
        alertTable,
        lookCompleteData,
        exportDom,
    },
    mounted() {
        this.$nextTick(() => {
            const nowTime = new Date();
            const startTime = this.timeFormat(nowTime, 'start');
            const endTime = this.timeFormat(nowTime, 'end');
            this.selectTimeData = [startTime, endTime];
            this.salesanalysisGclx();
        });
    },
    methods: {
        timeFormat(date, status) {
            let y;
            let m;
            let d;
            if (status === 'start') {
                y = date.getFullYear(0); // 年
                m = 1; // 月
                d = 1; // 日
                m = m > 9 ? m : '0' + m;
                d = d > 9 ? d : '0' + d;
            } else if (status === 'end') {
                y = date.getFullYear(); // 年
                m = date.getMonth() + 1; // 月
                d = date.getDate(); // 日
                m = m > 9 ? m : '0' + m;
                d = d > 9 ? d : '0' + d;
            }
            return `${y}-${m}-${d}`;
        },
        changeTimeFuncion() {
            this.salesanalysisGclx();
        },
        closeChildAlert() {
            this.showLookComp = false;
            this.showAlertTable = false;
        },
        volumeComparisonFun() {
            const myChart = this.$echarts.init(document.getElementById('volumeComparisonId'));
            const option = {
                legend: {
                    itemWidth: 8,
                    itemHeight: 8,
                },
                tooltip: {
                    data: ['已用方量', '预计方量'],
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '4%',
                    bottom: '-0%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    data: this.nameList,
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                            type: 'dashed',
                        },
                    },
                },
                yAxis: {
                    name: '单位(方)',
                    nameTextStyle: {
                        padding: [0, 0, -10, 45], // 四个数字分别为上右下左与原位置距离
                        color: '#979EA7',
                        fontSize: '10',
                    },
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                            type: 'dashed',
                        },
                    },
                },
                series: [
                    {
                        name: '已用方量',
                        data: this.yyflList,
                        type: 'bar',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(220, 220, 220, 0.8)',
                        },
                        label: {
                            show: true,
                            position: 'top',
                            fontFamily: 'technology',
                            padding: 5,
                            color: '#4D7DE0',
                            // backgroundColor: {
                            //     image: addBgImages(1, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: '#3E78C3', // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#44A1F5', // 100% 处的颜色
                                }], false),
                            },
                        },
                    },
                    {
                        name: '预计方量',
                        data: this.yjflList,
                        type: 'bar',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(220, 220, 220, 0.8)',
                        },
                        label: {
                            show: true,
                            position: 'top',
                            fontFamily: 'technology',
                            padding: 5,
                            color: '#EA8344',
                            // backgroundColor: {
                            //     image: addBgImages(4, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: '#EA8243', // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#F0AF56', // 100% 处的颜色
                                }], false),
                            },
                        },
                    },
                ],
            };
            // const that = this;
            // myChart.on('click', function () {
            //     that.showAlertTable = true;
            // });
            myChart.setOption(option, true);
        },
        // 工程用量统计
        salesanalysisGclx() {
            this.dataClose();
            this.$axios
                .get(`/interfaceApi/report/salesanalysis/gcyl/gclx?startDate=${this.selectTimeData[0]}&endDate=${this.selectTimeData[1]}`)
                .then(res => {
                    this.gcyltjData = res;
                    res.list.forEach(item => {
                        this.gcslTotal += Math.round(item.gcsl * 100) / 100; // 工程数量合计
                        this.yjflTotal += Math.round(item.yjfl * 100) / 100; // 预计方量合计
                        this.yyflTotal += Math.round(item.yyfl * 100) / 100; // 已用方量合计
                        this.ddslTotal += Math.round(item.ddsl * 100) / 100; // 订单数量合计
                        this.fhdslTotal += Math.round(item.fhdsl * 100) / 100; // 发货单数量合计
                        this.yjflList.push(item.yjfl);
                        this.yyflList.push(item.yyfl);
                        this.nameList.push(item.name);
                        return this.gcslTotal;
                    });
                    // 按工程数量统计
                    this.numberList = this.gcyltjData.list;
                    this.numberList.forEach((item, index) => {
                        if (index < 1) {
                            this.numberListA.push(item);
                        } else if (index >= 1 && index <= 2 ) {
                            this.numberListB.push(item);
                        } else if (index > 2) {
                            this.numberListC.push(item);
                        }
                    });
                    this.volumeComparisonFun();
                })
                .catch(err => {
                    console.log(err);
                });
        },
        dataClose() {
            this.gcyltjData = [];
            this.numberList = [];
            this.numberListA = [];
            this.numberListB = [];
            this.numberListC = [];
            this.yyflList = [];
            this.yjflList = [];
            this.nameList = [];
            this.gcslTotal = 0;
            this.yjflTotal = 0;
            this.yyflTotal = 0;
            this.ddslTotal = 0;
            this.fhdslTota = 0;
        },
    },
};

</script>
<style lang='stylus'>
.engineering-quantity
    width 100%
    background #fff
    .engineering-quantity-box
        padding .2rem
        background #fff
        height 100%
        .engineering-quantity-head
            display flex
            height .7rem
            border-bottom 1px solid #E7E7E7
            align-items center
            position relative
            .export
                position absolute
                right 0
                height .4rem
                top .12rem
                .export_btn
                    border: none;
                    height: 0.36rem;
                    font-size: 0.14rem;
                    min-width: 0.36rem;
                    line-height: 0.36rem;
                    cursor: pointer;
                    padding: 0 0.2rem;
                    border-radius: 2px;
                    background: #f1f4f7;
                    color: #2978ff;
                    text-align: center;
            p
                color #8593A7
                font-size .18rem
            .el-date-editor
                height 0.36rem !important
            button
                margin-left 0.1rem
                padding: 0 0.1rem;
                height .36rem
                background #2978ff
                border-radius .04rem
                display inline-block
                color #ffffff
                text-align center
                line-height .36rem
                cursor pointer
                border 0
        .engineering-quantity-table
            width 100%
            margin-top .1rem
            table
                border 1px solid #D7D7D7
                border-collapse:collapse;
                width 100%
                tr td:first-child
                    font-size .16rem
                    background #EDF0F5
                    color #022782
                    font-weight 800
                tr:first-child td:last-child
                    background #F5F2ED
                    color #823802
                    font-weight 800
                td
                    width 1.3rem
                    height .4rem
                    text-align center
                    font-size .14rem
                    &:last-child
                        color #C9661E
                .tableTitle
                    background: #edf0f5;
                    color: #022782;
                    font-weight 800
        .engineering-quantity-body
            display flex
            width 100%
            justify-content space-between
            margin-top .2rem
            height calc(100% - 3.4rem)
            .body-box-two
                flex 1
                border 1px solid #D7D7D7
                padding .2rem
                .echarts-two-right
                    display flex
                    justify-content space-between
                    align-items center
                    p
                        color #1577D2
                        height .25rem
                        padding 0 .2rem;
                        font-size .14rem
                        cursor pointer
                        border 1px solid #1577D2
                        margin-right .2rem
                h1
                    color #333333
                    font-size .18rem
                    font-weight 800
                    &:before
                        content '`'
                        width .06rem
                        height .2rem
                        background #4D7DE0
                        color #4D7DE0
                        margin-right .1rem
                &:first-child
                    margin-right .2rem
            .quantity-of-works
                .see-number-wrapper
                    height calc(100% - .2rem)
                    overflow-y auto
                .see-number-block
                    width 100%
                    margin-top .2rem
                    display flex
                    min-height 3.8rem
                    p
                        color #fff
                        span
                            font-family technology
                            color #fff
                            font-size .6rem
                            display block
                    div:first-child
                        flex 3.2
                        margin-right .05rem
                        p
                            width 100%
                            height 100%
                            display flex
                            flex-direction column
                            align-items center
                            justify-content center
                            background:linear-gradient(180deg,rgba(85,167,254,1),rgba(62,121,197,1));
                            font-size .28rem
                    div:nth-child(2)
                        flex 2.3
                        display flex
                        flex-direction column
                        p
                            width 100%
                            height 100%
                            display flex
                            flex-direction column
                            align-items center
                            justify-content center
                            font-size .28rem
                        p:first-child
                            flex 1.6
                            background:linear-gradient(180deg,rgba(240,174,86,1),rgba(234,131,67,1));
                        p:last-child
                            flex 1.4
                            margin-top .06rem
                            background:linear-gradient(180deg,rgba(118,224,168,1),rgba(19,189,99,1));
                    div:last-child
                        flex 1.9
                        margin-left .06rem
                        position relative
                        p
                            float left
                        p:nth-child(1)
                            width 100%
                            height 1rem
                            background:linear-gradient(180deg,rgba(115,200,177,1),rgba(76,162,149,1));
                            display flex
                            align-items center
                            justify-content center
                            span
                                margin-right .2rem
                        p:nth-child(2)
                            background:linear-gradient(180deg,rgba(250,158,148,1),rgba(228,79,79,1));
                            position absolute
                            top 1.06rem
                            left 0
                            width 49%
                            display flex
                            flex-direction column
                            align-items center
                            justify-content center
                            height 1rem
                            font-size .2rem
                            span
                                font-size .4rem
                                margin-bottom -.03rem
                        p:nth-child(3)
                            background:linear-gradient(180deg,rgba(122,204,251,1),rgba(42,160,233,1));
                            position absolute
                            top 1.06rem
                            right 0
                            width 49%
                            display flex
                            flex-direction column
                            align-items center
                            justify-content center
                            height 1rem
                            font-size .2rem
                            span
                                font-size .4rem
                                margin-bottom -.03rem
                        p:nth-child(4)
                            background:linear-gradient(180deg,rgba(200,150,252,1),rgba(151,89,214,1));
                            position absolute
                            top 2.1rem
                            left 0
                            width 32%
                            display flex
                            flex-direction column
                            align-items center
                            justify-content center
                            height calc(100% - 2.1rem)
                            font-size .2rem
                            span
                                font-size .4rem
                                margin-bottom -.03rem
                        p:nth-child(5)
                            background:linear-gradient(180deg,rgba(75,189,230,1),rgba(57,154,189,1));
                            width 29%
                            height .73rem
                            position absolute
                            top 2.1rem
                            left 34%
                            width 32%
                            display flex
                            flex-direction column
                            align-items center
                            justify-content center
                            font-size .16rem
                            span
                                font-size .34rem
                                margin-bottom -.03rem
                        p:nth-child(6)
                            background:linear-gradient(180deg,rgba(230,228,41,1),rgba(220,182,3,1));
                            width 29%
                            height .73rem
                            position absolute
                            top 2.1rem
                            right 0
                            width 32%
                            display flex
                            flex-direction column
                            align-items center
                            justify-content center
                            font-size .16rem
                            span
                                font-size .34rem
                                margin-bottom -.03rem
                        p:nth-child(7)
                            background:linear-gradient(180deg,rgba(254,192,74,1),rgba(237,146,66,1));
                            width 66%
                            height calc(100% - 2.86rem)
                            position absolute
                            right 0
                            bottom 0
                            display flex
                            align-items center
                            justify-content center
                            font-size .16rem
                            span
                                font-size .34rem
                                margin-bottom -.03rem
                                margin-right .2rem

</style>
